@keyframes loading-rotate {
  100% {
    transform: rotate(1turn);
  }
}
.adm-switch {
  --checked-color: var(--adm-color-primary);
  --height: 31px;
  --width: 51px;
  --border-width: 2px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
  align-self: center;
  cursor: pointer;
  /* 选中状态 */
  /* 禁用状态 */
  /* loading图标 */
}
.adm-switch input {
  display: none;
}
.adm-switch-checkbox {
  min-width: var(--width);
  height: var(--height);
  box-sizing: border-box;
  border-radius: 31px;
  background: var(--adm-color-border);
  z-index: 0;
  overflow: hidden;
  line-height: var(--height);
}
.adm-switch-checkbox:before {
  content: ' ';
  position: absolute;
  left: var(--border-width);
  top: var(--border-width);
  width: calc(100% - 2 * var(--border-width));
  height: calc(var(--height) - 2 * var(--border-width));
  border-radius: calc(var(--height) - 2 * var(--border-width));
  box-sizing: border-box;
  background: var(--adm-color-background);
  z-index: 1;
  transition: all 200ms;
  transform: scale(1);
}
.adm-switch-handle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--height) - 2 * var(--border-width));
  height: calc(var(--height) - 2 * var(--border-width));
  border-radius: calc(var(--height) - 2 * var(--border-width));
  background: var(--adm-color-text-light-solid);
  position: absolute;
  z-index: 2;
  top: var(--border-width);
  left: var(--border-width);
  transition: all 200ms;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 2px 11.5px 0 rgba(0, 0, 0, 0.08), -1px 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.adm-switch-inner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 calc(var(--height) - var(--border-width) + 4px);
  height: 100%;
  color: var(--adm-color-weak);
  transition: margin 200ms;
  font-size: var(--adm-font-size-7);
}
.adm-switch.adm-switch-checked .adm-switch-checkbox {
  background: var(--checked-color);
}
.adm-switch.adm-switch-checked .adm-switch-checkbox:before {
  transform: scale(0);
}
.adm-switch.adm-switch-checked .adm-switch-handle {
  left: calc(100% - (var(--height) - var(--border-width)));
}
.adm-switch.adm-switch-checked .adm-switch-inner {
  margin: 0 calc(var(--height) - var(--border-width) + 5px) 0 10px;
  color: var(--adm-color-text-light-solid);
}
.adm-switch.adm-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.adm-switch-spin-icon {
  width: 14px;
  height: 14px;
  animation: loading-rotate 1s linear infinite;
}
