.rc-steps {
  font-size: 0;
  width: 100%;
  line-height: 1.5;
  display: flex;
}
.rc-steps,
.rc-steps * {
  box-sizing: border-box;
}
.rc-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1;
  overflow: hidden;
}
.rc-steps-item:last-child {
  flex: none;
}
.rc-steps-item:last-child .rc-steps-item-tail,
.rc-steps-item:last-child .rc-steps-item-title:after {
  display: none;
}
.rc-steps-item-secondary-current .rc-steps-item-upper-content {
  border: 2px solid #108ee9;
}
.rc-steps-item-secondary-current .rc-steps-item-upper-content:after,
.rc-steps-item-secondary-current .rc-steps-item-upper-content:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.rc-steps-item-secondary-current .rc-steps-item-upper-content:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #FFF;
  border-width: 8px;
  margin-left: -9px;
}
.rc-steps-item-secondary-current .rc-steps-item-upper-content:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #108ee9;
  border-width: 11px;
  margin-left: -12px;
}
.rc-steps-item-secondary-current .rc-steps-item-upper-title {
  background: transparent;
  margin-bottom: -2px;
}
.rc-steps-item-sub-step {
  flex: 1 2;
  overflow: hidden !important;
}
.rc-steps-item-sub-step .rc-steps-item-icon {
  font-size: 0;
  width: 15px;
  height: 15px;
  margin-top: 5px;
}
.rc-steps-item-icon,
.rc-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.rc-steps-item-icon {
  border: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 26px;
  font-size: 14px;
  margin-right: 8px;
  transition: background-color 0.3s, border-color 0.3s;
}
.rc-steps-item-icon > .rc-steps-icon {
  line-height: 1;
  top: -1px;
  color: #108ee9;
  position: relative;
}
.rc-steps-item-icon > .rc-steps-icon.rcicon {
  font-size: 12px;
  position: relative;
  top: -2px;
}
.rc-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}
.rc-steps-item-tail:after {
  content: '';
  display: inline-block;
  background: #e9e9e9;
  height: 1px;
  border-radius: 1px;
  width: 100%;
  transition: background .3s;
}
.rc-steps-item-content {
  margin-top: 3px;
}
.rc-steps-item-upper-content {
  display: inline-block;
  text-align: center;
  margin-top: 8px;
  width: 100px;
  position: absolute;
}
.rc-steps-item-upper-content:after {
  content: '';
  height: 1px;
  display: block;
  position: absolute;
  top: 0.55em;
  left: 100%;
}
.rc-steps-item-upper-title {
  font-size: 14px;
  color: #666;
  font-weight: bold;
  display: inline-block;
  padding: 3px 4px 3px 4px;
  overflow: hidden;
}
.rc-steps-item-title {
  font-size: 14px;
  margin-bottom: 4px;
  color: #666;
  font-weight: bold;
  display: inline-block;
  padding-right: 10px;
  position: relative;
}
.rc-steps-item-title:after {
  content: '';
  height: 1px;
  width: 1000px;
  background: #e9e9e9;
  display: block;
  position: absolute;
  top: 0.55em;
  left: 100%;
}
.rc-steps-item-description {
  font-size: 12px;
  color: #999;
}
.rc-steps-item-wait .rc-steps-item-icon {
  border-color: #ccc;
  background-color: #fff;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
  color: #ccc;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #ccc;
}
.rc-steps-item-wait .rc-steps-item-title {
  color: gba(0, 0, 0, 0.43);
}
.rc-steps-item-wait .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-wait .rc-steps-item-description {
  color: gba(0, 0, 0, 0.43);
}
.rc-steps-item-wait .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}
.rc-steps-item-process .rc-steps-item-icon {
  border-color: #108ee9;
  background-color: #fff;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #108ee9;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #108ee9;
}
.rc-steps-item-process .rc-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
.rc-steps-item-process .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-process .rc-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.rc-steps-item-process .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}
.rc-steps-item-process .rc-steps-item-icon {
  background: #108ee9;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #fff;
}
.rc-steps-item-finish .rc-steps-item-icon {
  border-color: #108ee9;
  background-color: #fff;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: #108ee9;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #108ee9;
}
.rc-steps-item-finish .rc-steps-item-title {
  color: gba(0, 0, 0, 0.43);
}
.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: #108ee9;
}
.rc-steps-item-finish .rc-steps-item-description {
  color: gba(0, 0, 0, 0.43);
}
.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: #108ee9;
}
.rc-steps-item-error .rc-steps-item-icon {
  border-color: #f50;
  background-color: #fff;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #f50;
}
.rc-steps-item-error .rc-steps-item-title {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-error .rc-steps-item-description {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}
.rc-steps-item.rc-steps-next-error .rc-steps-item-title:after {
  background: #f50;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
  margin-right: 10px;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item:last-child {
  margin-right: 0;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-tail {
  display: none;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-description {
  max-width: 100px;
}
.rc-steps-item-custom .rc-steps-item-icon {
  background: none;
  border: 0;
  width: auto;
  height: auto;
}
.rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  font-size: 20px;
  top: 1px;
  width: 20px;
  height: 20px;
}
.rc-steps-item-custom.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #108ee9;
}
.rc-steps-small .rc-steps-item-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 18px;
  font-size: 12px;
  margin-right: 10px;
}
.rc-steps-small .rc-steps-item-icon > .rc-steps-icon {
  font-size: 12px;
  font-size: 9px \9;
  transform: scale(0.75);
  top: -1px;
}
.rc-steps-small .rc-steps-item-content {
  margin-top: 0;
}
.rc-steps-small .rc-steps-item-title {
  font-size: 12px;
  margin-bottom: 4px;
  color: #666;
  font-weight: bold;
}
.rc-steps-small .rc-steps-item-description {
  font-size: 12px;
  color: #999;
}
.rc-steps-small .rc-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
.rc-steps-small .rc-steps-item-tail:after {
  height: 1px;
  border-radius: 1px;
  width: 100%;
}
.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  border-radius: 0;
  border: 0;
  background: none;
}
.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  font-size: 20px;
  top: -2.5px;
  transform: none;
}
.rc-steps-vertical {
  display: block;
}
.rc-steps-vertical .rc-steps-item {
  display: block;
  overflow: visible;
}
.rc-steps-vertical .rc-steps-item-sub-step .rc-steps-item-icon {
  margin-left: 5px;
}
.rc-steps-vertical .rc-steps-item-icon {
  float: left;
}
.rc-steps-vertical .rc-steps-item-icon-inner {
  margin-right: 16px;
}
.rc-steps-vertical .rc-steps-item-content {
  min-height: 48px;
  overflow: hidden;
  display: block;
}
.rc-steps-vertical .rc-steps-item-upper-content {
  display: block;
  text-align: center;
  margin-top: 0px;
  width: 100px;
  position: absolute;
  top: 0px;
  left: 50%;
}
.rc-steps-vertical .rc-steps-item-title {
  line-height: 26px;
}
.rc-steps-vertical .rc-steps-item-title:after {
  display: none;
}
.rc-steps-vertical .rc-steps-item-description {
  padding-bottom: 12px;
}
.rc-steps-vertical .rc-steps-item-tail {
  position: absolute;
  left: 13px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 30px 0 4px 0;
}
.rc-steps-vertical .rc-steps-item-tail:after {
  height: 100%;
  width: 1px;
}
.rc-steps-vertical.rc-steps-small .rc-steps-item-tail {
  position: absolute;
  left: 9px;
  top: 0;
  padding: 22px 0 4px 0;
}
.rc-steps-vertical.rc-steps-small .rc-steps-item-title {
  line-height: 18px;
}
.rc-steps-label-vertical .rc-steps-item {
  overflow: visible;
}
.rc-steps-label-vertical .rc-steps-item-tail {
  padding: 0px 24px;
  margin-left: 48px;
}
.rc-steps-label-vertical .rc-steps-item-content {
  display: block;
  text-align: center;
  margin-top: 8px;
  width: 100px;
}
.rc-steps-label-vertical .rc-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
.rc-steps-label-vertical .rc-steps-item-title {
  padding-right: 0;
}
.rc-steps-label-vertical .rc-steps-item-title:after {
  display: none;
}
.rc-steps-label-vertical .rc-steps-item-description {
  text-align: center;
}
.rc-steps-dot .rc-steps-item-tail {
  width: 100%;
  top: 1px;
  margin: 0 0 0 50px;
  padding: 0;
}
.rc-steps-dot .rc-steps-item-tail:after {
  height: 3px;
}
.rc-steps-dot .rc-steps-item-icon {
  padding-right: 0;
  width: 5px;
  height: 5px;
  line-height: 5px;
  border: 0;
  margin-left: 48px;
}
.rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
}
.rc-steps-dot .rc-steps-item-process .rc-steps-dot .rc-steps-item-icon {
  top: -1px;
  width: 7px;
  height: 7px;
  line-height: 7px;
}
.rc-steps-dot .rc-steps-item-process .rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
  border-radius: 3.5px;
}
@font-face {
  font-family: 'anticon';
  src: url('//at.alicdn.com/t/font_1434092639_4910953.eot');
  /* IE9*/
  src: url('//at.alicdn.com/t/font_1434092639_4910953.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//at.alicdn.com/t/font_1434092639_4910953.woff') format('woff'), /* chrome、firefox */ url('//at.alicdn.com/t/font_1434092639_4910953.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('//at.alicdn.com/t/font_1434092639_4910953.svg#iconfont') format('svg');
  /* iOS 4.1- */
}
.rcicon {
  position: relative;
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0px;
  -moz-osx-font-smoothing: grayscale;
}
.rcicon:before {
  display: block;
  font-family: "anticon" !important;
}
.rcicon-step-backward:before {
  content: "\e662";
}
.rcicon-step-forward {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-step-forward:before {
  content: "\e662";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-fast-backward:before {
  content: "\e62a";
}
.rcicon-fast-forward {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-fast-forward:before {
  content: "\e62a";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-shrink:before {
  content: "\e65f";
}
.rcicon-arrow-salt:before {
  content: "\e608";
}
.rcicon-caret-down:before {
  content: "\e60f";
}
.rcicon-caret-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rcicon-caret-left:before {
  content: "\e60f";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rcicon-caret-up {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-caret-up:before {
  content: "\e60f";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-caret-right {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rcicon-caret-right:before {
  content: "\e60f";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.rcicon-caret-circle-right:before {
  content: "\e60d";
}
.rcicon-caret-circle-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-caret-circle-left:before {
  content: "\e60d";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-caret-circle-o-right:before {
  content: "\e60e";
}
.rcicon-caret-circle-o-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-caret-circle-o-left:before {
  content: "\e60e";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-circle-right:before {
  content: "\e602";
}
.rcicon-circle-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-circle-left:before {
  content: "\e602";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-circle-o-right:before {
  content: "\e603";
}
.rcicon-circle-o-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-circle-o-left:before {
  content: "\e603";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-double-right:before {
  content: "\e604";
}
.rcicon-double-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-double-left:before {
  content: "\e604";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-verticle-right:before {
  content: "\e605";
}
.rcicon-verticle-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-verticle-left:before {
  content: "\e605";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-forward:before {
  content: "\e630";
}
.rcicon-backward {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-backward:before {
  content: "\e630";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-rollback:before {
  content: "\e65a";
}
.rcicon-retweet:before {
  content: "\e659";
}
.rcicon-right:before {
  content: "\e611";
}
.rcicon-down {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rcicon-down:before {
  content: "\e611";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rcicon-left {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rcicon-left:before {
  content: "\e611";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rcicon-up {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rcicon-up:before {
  content: "\e611";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.rcicon-question:before {
  content: "\e655";
}
.rcicon-question-circle:before {
  content: "\e656";
}
.rcicon-question-circle-o:before {
  content: "\e657";
}
.rcicon-plus:before {
  content: "\e651";
}
.rcicon-plus-circle:before {
  content: "\e652";
}
.rcicon-plus-circle-o:before {
  content: "\e653";
}
.rcicon-pause:before {
  content: "\e64c";
}
.rcicon-pause-circle:before {
  content: "\e64d";
}
.rcicon-pause-circle-o:before {
  content: "\e64e";
}
.rcicon-minus:before {
  content: "\e646";
}
.rcicon-minus-circle:before {
  content: "\e647";
}
.rcicon-minus-circle-o:before {
  content: "\e648";
}
.rcicon-info-circle:before {
  content: "\e637";
}
.rcicon-info-circle-o:before {
  content: "\e638";
}
.rcicon-info:before {
  content: "\e63a";
}
.rcicon-exclamation:before {
  content: "\e627";
}
.rcicon-exclamation-circle:before {
  content: "\e628";
}
.rcicon-exclamation-circle-o:before {
  content: "\e629";
}
.rcicon-cross:before {
  content: "\e61e";
}
.rcicon-cross-circle:before {
  content: "\e61f";
}
.rcicon-cross-circle-o:before {
  content: "\e620";
}
.rcicon-check:before {
  content: "\e613";
}
.rcicon-check-circle:before {
  content: "\e614";
}
.rcicon-check-circle-o:before {
  content: "\e615";
}
.rcicon-clock-circle:before {
  content: "\e616";
}
.rcicon-clock-circle-o:before {
  content: "\e617";
}
.rcicon-lock:before {
  content: "\e641";
}
.rcicon-android:before {
  content: "\e601";
}
.rcicon-apple:before {
  content: "\e606";
}
.rcicon-area-chart:before {
  content: "\e607";
}
.rcicon-bar-chart:before {
  content: "\e609";
}
.rcicon-bars:before {
  content: "\e60a";
}
.rcicon-book:before {
  content: "\e60b";
}
.rcicon-calendar:before {
  content: "\e60c";
}
.rcicon-cloud:before {
  content: "\e618";
}
.rcicon-cloud-download:before {
  content: "\e619";
}
.rcicon-code:before {
  content: "\e61a";
}
.rcicon-copy:before {
  content: "\e61c";
}
.rcicon-credit-card:before {
  content: "\e61d";
}
.rcicon-delete:before {
  content: "\e621";
}
.rcicon-desktop:before {
  content: "\e622";
}
.rcicon-download-line:before {
  content: "\e623";
}
.rcicon-edit:before {
  content: "\e624";
}
.rcicon-ellipsis:before {
  content: "\e625";
}
.rcicon-environment:before {
  content: "\e626";
}
.rcicon-file:before {
  content: "\e62c";
}
.rcicon-file-text:before {
  content: "\e62d";
}
.rcicon-folder:before {
  content: "\e62e";
}
.rcicon-folder-open:before {
  content: "\e62f";
}
.rcicon-github:before {
  content: "\e631";
}
.rcicon-hdd:before {
  content: "\e632";
}
.rcicon-frown:before {
  content: "\e633";
}
.rcicon-meh:before {
  content: "\e634";
}
.rcicon-inbox:before {
  content: "\e635";
}
.rcicon-laptop:before {
  content: "\e63d";
}
.rcicon-large:before {
  content: "\e63e";
}
.rcicon-line-chart:before {
  content: "\e63f";
}
.rcicon-link:before {
  content: "\e640";
}
.rcicon-logout:before {
  content: "\e642";
}
.rcicon-mail:before {
  content: "\e643";
}
.rcicon-menu-fold:before {
  content: "\e644";
}
.rcicon-menu-unfold:before {
  content: "\e645";
}
.rcicon-mobile:before {
  content: "\e649";
}
.rcicon-notification:before {
  content: "\e64a";
}
.rcicon-paper-clip:before {
  content: "\e64b";
}
.rcicon-picture:before {
  content: "\e64f";
}
.rcicon-pie-chart:before {
  content: "\e650";
}
.rcicon-poweroff:before {
  content: "\e654";
}
.rcicon-reload:before {
  content: "\e658";
}
.rcicon-search:before {
  content: "\e65b";
}
.rcicon-setting:before {
  content: "\e65c";
}
.rcicon-share-alt:before {
  content: "\e65d";
}
.rcicon-shopping-cart:before {
  content: "\e65e";
}
.rcicon-smile:before {
  content: "\e661";
}
.rcicon-tablet:before {
  content: "\e664";
}
.rcicon-tag:before {
  content: "\e665";
}
.rcicon-tags:before {
  content: "\e666";
}
.rcicon-to-top:before {
  content: "\e667";
}
.rcicon-unlock:before {
  content: "\e668";
}
.rcicon-upload:before {
  content: "\e669";
}
.rcicon-user:before {
  content: "\e66a";
}
.rcicon-video-camera:before {
  content: "\e66b";
}
.rcicon-windows:before {
  content: "\e66c";
}
.rcicon-loading:before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  -o-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
  content: "\e610";
}
:root .rcicon-step-forward,
:root .rcicon-fast-forward,
:root .rcicon-left,
:root .rcicon-up,
:root .rcicon-down,
:root .rcicon-caret-left,
:root .rcicon-caret-up,
:root .rcicon-caret-right,
:root .rcicon-caret-circle-left,
:root .rcicon-caret-circle-o-left,
:root .rcicon-circle-left,
:root .rcicon-circle-o-left,
:root .rcicon-double-left,
:root .rcicon-verticle-left,
:root .rcicon-backward {
  filter: none;
}
